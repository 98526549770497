import { Container, Row, Col, Button } from 'reactstrap';

import ProductTile from 'shared/components/ProductTile';
import axiosInstance from 'shared/utils/axiosInstance';
import SEO from 'shared/components/SEO';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { GetServerSideProps } from 'next';

const Home = ({
  bestSellers: bestSellersFromProps,
  // latestProducts,
  limitedEditions: limitedEditionsFromProps,
  banners,
  brands,
}) => {
  const [bestSellerCarouselSkip, setBestSellerCarouselSkip] = useState(null);
  const [limitedEditionCarouselSkip, setLimitedEditionCarouselSkip] = useState(null);
  const [bestSellers, setBestSellers] = useState(bestSellersFromProps);
  const [limitedEditions, setLimitedEditions] = useState(limitedEditionsFromProps);

  useEffect(() => {
    async function updateBestSellers() {
      const { data } = await axiosInstance.get('/product', {
        params: {
          sort: 'bestsellers',
          limit: 4,
          app: process.env.NEXT_PUBLIC_APP,
          skip: bestSellerCarouselSkip,
          onlyWithStock: true,
        },
      });
      if (data && data.products) setBestSellers(data.products);
    }
    if (bestSellerCarouselSkip !== null) updateBestSellers();
  }, [bestSellerCarouselSkip]);

  useEffect(() => {
    async function updateLimitedEdtions() {
      const { data } = await axiosInstance.get('/product', {
        params: {
          limitedEdition: true,
          sort: 'bestsellers',
          limit: 4,
          app: process.env.NEXT_PUBLIC_APP,
          skip: limitedEditionCarouselSkip,
          onlyWithStock: true,
        },
      });
      if (data && data.products) setLimitedEditions(data.products);
    }
    if (limitedEditionCarouselSkip !== null) updateLimitedEdtions();
  }, [limitedEditionCarouselSkip]);

  function updateBestSellerCarousel(direction) {
    switch (direction) {
      case 'next':
        if (!bestSellerCarouselSkip) setBestSellerCarouselSkip(4);
        else setBestSellerCarouselSkip((prev) => prev + 4);

        break;
      case 'prev':
        setBestSellerCarouselSkip((prev) => (prev ? prev - 4 : 0));
        break;

      default:
        break;
    }
  }

  function updateLimitedEdtionCarousel(direction) {
    switch (direction) {
      case 'next':
        if (!limitedEditionCarouselSkip) setLimitedEditionCarouselSkip(1);
        else setLimitedEditionCarouselSkip((prev) => prev + 1);

        break;
      case 'prev':
        setLimitedEditionCarouselSkip((prev) => (prev ? prev - 1 : 0));
        break;

      default:
        break;
    }
  }

  return (
    <div id="homePage">
      <SEO canonical="/" title="Deals die smaken naar meer!" />
      <Container>
        <h1 style={{ fontSize: 24 }} className="my-4 text-uppercase text-center">
          Deals die smaken naar meer
        </h1>
        {/* <section className="my-4 border-top border-bottom">
        <p className="py-4">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos magni amet, eaque vero neque
          temporibus ipsam officiis est asperiores sint pariatur facere. Recusandae inventore
          officia, eaque asperiores a suscipit omnis?
        </p>
      </section> */}
        {banners &&
          banners.map(({ image, link, _id }) => (
            <a href={link} key={`hpBanner_${_id}`} className="d-block my-5">
              <img
                key={`bannerImage_${_id}`}
                className="img-fluid"
                srcSet={`${image.src.large} 1400w, ${image.src.small} 800w`}
                src={image.src.large}
                alt={image.description}
                height="auto"
              />
            </a>
          ))}
      </Container>
      {bestSellers && bestSellers.length > 0 && (
        <section className="productSection py-5 bg-blue-100">
          <Container>
            <h2 style={{ fontSize: 20 }} className="d-inline-block text-uppercase mb-0">
              Onze bestsellers
            </h2>
            <hr />
            <Row className="products position-relative g-0">
              <div
                className="d-none d-lg-flex position-absolute align-items-center h-100 w-auto"
                style={{ left: -10 }}
              >
                <Button
                  color="primary"
                  style={{ width: 30, height: 30, zIndex: 10 }}
                  className="rounded-circle p-0 d-flex align-items-center justify-content-center right"
                  onClick={() => updateBestSellerCarousel('prev')}
                  disabled={!bestSellerCarouselSkip}
                >
                  <MdChevronLeft size="26" color="white" />
                </Button>
              </div>
              {bestSellers.map((product) => (
                <Col xs="6" lg="3" key={product._id} className="px-1">
                  <ProductTile product={product} />
                </Col>
              ))}
              <div
                className="d-none d-lg-flex position-absolute align-items-center h-100 w-auto"
                style={{ right: -10 }}
              >
                <Button
                  color="primary"
                  style={{ width: 30, height: 30 }}
                  className="rounded-circle p-0 d-flex align-items-center justify-content-center right"
                  onClick={() => updateBestSellerCarousel('next')}
                >
                  <MdChevronRight size="26" color="white" />
                </Button>
              </div>
            </Row>
          </Container>
        </section>
      )}
      {/* {latestProducts && latestProducts.length > 0 && (
      <section className="productSection py-5">
        <Container>
          <div className="productHeader mb-5 d-flex justify-content-center align-items-center">
            <h2
              style={{ fontSize: 20 }}
              className="py-2 px-5 mb-0 bg-white text-uppercase border border-primary text-primary text-center"
            >
              Nieuw in ons assortiment
            </h2>
          </div>
          <Row className="products g-0">
            {latestProducts.map((product) => (
              <Col xs="6" lg="3" key={product._id} className="px-1">
                <ProductTile
                  product={product}
                  href="/[categoryName]/[slug]"
                  as={`/${product.category}/${product.slug}`}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    )} */}
      {limitedEditions && limitedEditions.length > 0 && (
        <section className="productSection py-5">
          <Container>
            <h2 style={{ fontSize: 20 }} className="text-uppercase">
              Limited editions
            </h2>
            <hr />
            <Row className="products position-relative g-0">
              <div
                className="d-none d-lg-flex position-absolute align-items-center h-100 w-auto"
                style={{ left: -10 }}
              >
                <Button
                  color="primary"
                  style={{ width: 30, height: 30, zIndex: 10 }}
                  className="rounded-circle p-0 d-flex align-items-center justify-content-center right"
                  onClick={() => updateLimitedEdtionCarousel('prev')}
                  disabled={!limitedEditionCarouselSkip}
                >
                  <MdChevronLeft size="26" color="white" />
                </Button>
              </div>
              {limitedEditions.map((product) => (
                <Col xs="6" lg="3" key={product._id} className="px-1">
                  <ProductTile product={product} />
                </Col>
              ))}
              <div
                className="d-none d-lg-flex position-absolute align-items-center h-100 w-auto"
                style={{ right: -10 }}
              >
                <Button
                  color="primary"
                  style={{ width: 30, height: 30 }}
                  className="rounded-circle p-0 d-flex align-items-center justify-content-center right"
                  onClick={() => updateLimitedEdtionCarousel('next')}
                >
                  <MdChevronRight size="26" color="white" />
                </Button>
              </div>
            </Row>
          </Container>
        </section>
      )}{' '}
      {brands && brands.length > 0 && (
        <div className="brands border-top border-bottom">
          <Container className="pt-5 pb-5">
            <div className="d-flex align-items-center justify-content-around flex-wrap">
              {brands.map((brand) => (
                <img
                  key={`footerBrand-${brand._id}`}
                  style={{ width: 100 }}
                  className="img-fluid"
                  src={brand.logo.src}
                  alt={`${brand.name} logo`}
                />
              ))}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async () => {
  const [
    { data: productData },
    // { data: latestProducts },
    { data: limitedEditions },
    { data: bannerData },
    { data: brandData },
  ] = await Promise.all([
    axiosInstance.get('/product', {
      params: {
        sort: 'bestsellers',
        limit: 4,
        app: process.env.NEXT_PUBLIC_APP,
        onlyWithStock: true,
      },
    }),
    // axiosInstance.get('/product', { params: { sort: 'latest', limit: 4 } }),
    axiosInstance.get('/product/random', {
      params: {
        limitedEdition: true,
        limit: 4,
        app: process.env.NEXT_PUBLIC_APP,
        onlyWithStock: true,
      },
    }),
    axiosInstance.get('/banner', {
      params: { published: true, limit: 2, app: process.env.NEXT_PUBLIC_APP, page: 'home' },
    }),
    axiosInstance.get('/brand', {
      params: { limit: 6, app: process.env.NEXT_PUBLIC_APP },
    }),
  ]);

  return {
    props: {
      bestSellers: productData.products,
      // latestProducts: latestProducts.products,
      limitedEditions: limitedEditions.products,
      banners: bannerData.banners,
      brands: brandData.brands,
    },
  };
};
